import { wsService } from "./wsService";

const section = "document_types";

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}


export const documentsTypeService = {
  section,
  filter,
};
