<template>
  <div>
    <v-row>
      <v-col md="12" v-if="!isViewModal">
        <v-btn size="small" color="blue-grey" prepend-icon="$plus" class="pl-4 text-white" @click="newPresentation">
          <span>Agregar presentacion</span>
        </v-btn>
      </v-col>

      <v-col md="12">
        <v-data-table :headers="header_custome" :items="product.presentations" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.barcode }}
              </td>
              <td>
                {{ item.presentation_type != null ? item.presentation_type.name : "" }}
              </td>
              <td>
                {{ item.composite_presentation != null ? item.composite_presentation.name : "" }}
              </td>
              <td>
                {{ item.amount_composite_presentation }}
              </td>
              <td>
                {{ item.storage_unit != null ? item.storage_unit.name : "" }}
              </td>

              <td>
                {{ item.weight != null ? item.weight + (item.weight_unit != null ? item.weight_unit.name : "") : "" }}
              </td>
              <td>
                {{ item.heigth != null && item.heigth != "" ? item.heigth + "x" + item.width + "x" + item.length + (item.measure_unit != null ? item.measure_unit.name : "") : "" }}
              </td>

              <!--<td class="d-flex justify-content-center align-items-center">
                <v-checkbox label="" :disabled="true" v-model="item.sellable"></v-checkbox>
              </td>-->

              <td class="text-center" style="width: 90px">
                <v-tooltip bottom v-if="!isViewModal">
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" small color="blue" class="mr-2" v-on:click="editPresentation(item)" size="small"> mdi-pencil</v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom v-if="!isViewModal">
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" small color="red" class="mr-2" v-on:click="removePresentation(item, 'custom')" size="small">mdi-delete </v-icon>
                  </template>
                  <span>Remover</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- START FORM DIALOG -->
    <ModalPresentacion :dialog="dialog_presentacion" :itemEdit="itemEdit" :product="product" @updateDialog="dialog_presentacion = false" @addPresentation="addPresentation" />
  </div>
</template>

<script>
import ModalPresentacion from "./ModalPresentacion.vue";
export default {
  created() {
    this.$validator = this.validator;
  },
  components: { ModalPresentacion },
  props: {
    open: Boolean,
    product: Object,
    addProduct: Function,
    removeProduct: Function,
    validator: Object,
    isViewModal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    header_custome: [
      { title: "Nombre", key: "name", sortable: true },
      { title: "Codigo de barras", key: "barcode", sortable: false },
      { title: "Tipo", key: "price", sortable: false },
      { title: "Composicion", key: "product", sortable: false },
      { title: "Cant. de unidades", key: "quantity", sortable: false },
      { title: "U. almacenamiento", key: "measure_unit", sortable: false },
      { title: "Peso", key: "weight", sortable: false },
      { title: "Medidas", key: "length", sortable: false },
      { title: "Accion", key: "action", sortable: false },
    ],
    ivas: [],
    dialog_presentacion: false,
    itemEdit: null,
    itemEditIndex: null,
  }),
  watch: {
    product: {
      handler: function (newValue) {},
      deep: true,
    },
  },
  mounted() {},
  methods: {
    newPresentation() {
      this.itemEdit = null;
      this.itemEditIndex = null;
      this.dialog_presentacion = true;
    },
    editPresentation(item) {
      this.itemEditIndex = this.product.presentations.indexOf(item);
      this.itemEdit = Object.assign({}, item);
      this.dialog_presentacion = true;
    },
    addPresentation(item) {
      console.log("addPresentation",item)
      if (this.itemEditIndex == null) {
        this.$emit("addProduct", item);
      } else {
        this.$emit("addProduct", item, this.itemEditIndex);
      }
      this.dialog_presentacion = false;
    },
    removePresentation(item, colection_name) {
      if (colection_name == "custom") {
        const index_item = this.product.presentations.indexOf(item);
        if (index_item > -1) this.$emit("removeProduct", index_item);
      }
    },
  },
};
</script>
