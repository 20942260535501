<template>
  <v-card rounded="0" theme="dark" @mouseenter="handleExpandMenu(true)" @mouseleave="handleExpandMenu(false)" height="100vh" class="bg-dark mx-auto overflow-auto">
    <div>
      <div style="height: 100px" class="d-flex justify-content-center align-items-center">
        <v-img v-show="expandMenu" transition="fade-transition" :src="require('@/assets/images/logo.png')" style="max-width: 120px" />

        <v-img v-show="!expandMenu" transition="fade-transition" :src="require('@/assets/images/logo-mini.png')" contain height="40%" />
      </div>

      <v-divider class="mt-0"></v-divider>
    </div>

    <div style="scroll-margin-top: 100px" v-for="item in items" :key="item.title">
      <v-list density="default" class="pt-1 pb-1" v-if="item.visible" nav>
        <v-list-group v-if="item.visible && 'items' in item && item.items.length > 0" v-model="item.active" :key="item.title" no-action>
          <template v-slot:activator="{ props }">
            <v-list-item dense v-bind="props" :title="item.title">
              <template v-slot:prepend> <font-awesome-icon :icon="item.icon" class="sidebar-icon mr-1" /> </template>
            </v-list-item>
          </template>

          <router-link v-for="subItem in item.items" :key="subItem.title" :to="'/' + subItem.path">
            <v-list-item :class="expandMenu ? 'subitem text-white' : 'subitem text-white hidden'" v-if="subItem.visible" ripple @click="close">
              <template v-slot:prepend>
                <font-awesome-icon v-if="subItem.icon != ''" :icon="subItem.icon" class="sidebar-icon mr-1" />
              </template>
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list-group>

        <router-link v-else-if="item.visible" :to="'/' + item.path">
          <v-list-item :active="item.path.includes($route.path)" dense class="text-white pl-1" :key="item.title" link>
            <template v-slot:prepend> <font-awesome-icon :icon="item.icon" class="sidebar-icon mr-1" /> </template>

            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </router-link>
      </v-list>
    </div>
  </v-card>
</template>

<script>
import Constant from "@/constants/sections";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
export default {
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      isExternal: null,
      test: "hola",
      allItems: [
        { title: "Monitoreo", icon: "desktop", path: Constant.SECTION_MONITORING, visible: false, active: false },
        {
          title: "Recepción",
          icon: "arrow-right",
          active: false,
          visible: false,
          items: [{ title: "ASN", icon: "", path: Constant.SECTION_ASN, visible: false }],
        },
        {
          title: "Expedición",
          icon: "arrow-left",
          active: false,
          visible: false,
          items: [{ title: "Pedido de salida", icon: "", path: Constant.SECTION_ASN, visible: false }],
        },
        { title: "Tareas", icon: "list-check", path: Constant.SECTION_TASKS, visible: false, active: false },
        { title: "Inventario", icon: "boxes-stacked", path: Constant.SECTION_INVENTORY, visible: false, active: false },
        { title: "LPNs", icon: "barcode", path: Constant.SECTION_LPN, visible: false, active: false },
        {
          title: "Productos",
          icon: "box",
          path: Constant.SECTION_PRODUCTS,
          visible: false,
          active: false,
          items: [
            { title: "Productos", icon: "", path: Constant.SECTION_PRODUCTS, visible: false },
            { title: "Rubros", icon: "", path: Constant.SECTION_RUBROS, visible: false },
            { title: "Subrubros", icon: "", path: Constant.SECTION_SUBRUBROS, visible: false },
            { title: "Proveedores", icon: "", path: Constant.SECTION_SUPPLIER, visible: false },
            { title: "Tipos de producto", icon: "", path: Constant.SECTION_PRODUCT_TYPES, visible: false },
            { title: "Reglas", icon: "", path: Constant.SECTION_RULES, visible: false },
          ],
        },
        {
          title: "Clientes",
          icon: "users",
          active: false,
          visible: false,
          items: [
            { title: "Clientes", icon: "", path: Constant.SECTION_CLIENTS, visible: false },
            { title: "SubClientes", icon: "", path: Constant.SECTION_SUBCLIENTS, visible: false },
          ],
        },
        {
          title: "Administrar",
          icon: "cog",
          active: false,
          visible: false,
          items: [
            { title: "Sucursales", icon: "", path: Constant.SECTION_SUCURSALES, visible: false }, //building
            { title: "Almacenes", icon: "", path: Constant.SECTION_ALMACENES, visible: false }, //warehouse
            { title: "Zonas", icon: "", path: Constant.SECTION_ZONAS, visible: false }, //signs-post
            { title: "Categorias de zonas", icon: "", path: Constant.SECTION_CATEGORIAS_ZONAS, visible: false }, //layer-group
            { title: "Tipos de ubicaciones", icon: "", path: Constant.SECTION_TIPOS_UBICACIONES, visible: false }, //layer-group
            { title: "Muelles", icon: "", path: Constant.SECTION_DOCKS, visible: false },
            { title: "Colaboradores", icon: "", path: Constant.SECTION_EMPLOYEES, visible: false }, //user
            { title: "Roles", icon: "", path: Constant.SECTION_ROLES, visible: false }, //user-cog
          ],
        },
      ],
      items: [],
      right: null,
      expandMenu: this.$store.state.sidebar.open ? true : false,
      isMobile: false,
      show: true,
    };
  },
  mounted() {
    this.loadSideBarExternal();
  },
  watch: {
    "$store.state.sidebar.open": function () {
      if (this.$store.state.sidebar.open) {
        this.expandMenu = true;
      } else {
        this.expandMenu = false;
      }
    },
  },
  methods: {
    handleExpandMenu(value) {
      if (this.$store.state.sidebar.open) {
        this.expandMenu = true;
      } else {
        this.expandMenu = value;
      }
    },
    close() {},
    loadSideBarExternal() {
      this.items = this.getItemsAvailable();
    },
    getItemsAvailable() {
      let itemsAvailable = JSON.parse(JSON.stringify(this.allItems));
      itemsAvailable.forEach((item) => {
        if ("items" in item) {
          let any_visible = false;
          item.items.forEach((sub_item) => {
            sub_item.visible = sub_item.path in this.roles && this.roles[sub_item.path].sidebar;
            if (sub_item.visible) any_visible = true;

            //hardcodee para dev
            sub_item.visible = true;
          });
          item.visible = any_visible;
        } else {
          item.visible = item.path in this.roles && this.roles[item.path].sidebar;
        }
        //hardcodee para dev
        item.visible = true;
      });
      return itemsAvailable;
    },
  },
};
</script>
