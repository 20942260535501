<template>
  <v-layout>
    <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog', false)" max-width="700px" transition="dialog-bottom-transition">
      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="addItem">
          <v-card-title>
            <span class="headline">Resumen de recepción ASN #{{ asn != null ? asn.id : "" }}</span>
          </v-card-title>

          <v-card-text> </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { asnService } from "@/libs/ws/asnService";

export default {
  components: {},
  mixins: [],
  props: {
    dialog: Boolean,
    asn: { Type: Object, default: null },
  },
  data() {
    return {};
  },
  watch: {
    dialog() {
      if (this.dialog) {
        //this.getSummary();
      }
    },
  },
  mounted() {},
  methods: {
    getSummary() {
      asnService.getSummary({ id: this.asn.id }).then((response) => {
        console.log(response);
      });
    },
  },
};
</script>
