import UserType from "@/constants/user_type";
import Login from "@/views/Login/Login.vue";
import Dashboard from "@/views/Dashboard/Dashboard.vue";
import Sucursales from "@/views/Sucursales/Sucursales.vue";
import Almacenes from "@/views/Almacenes/Almacenes.vue";
import Employees from "@/views/Employees/Employees.vue";
import Clients from "@/views/Clients/Clients.vue";
import SubClients from "@/views/SubClients/SubClients.vue";
import CategoriasZonas from "@/views/CategoriasZonas/CategoriasZonas.vue";
import TiposUbicaciones from "@/views/TiposUbicaciones/TiposUbicaciones.vue";
import Zonas from "@/views/Zonas/Zonas.vue";
import Roles from "@/views/Roles/Roles.vue";
import Productos from "@/views/Productos/Productos.vue";
import Rubros from "@/views/Rubros/Rubros.vue";
import SubRubros from "@/views/SubRubros/SubRubros.vue";
import TipoProductos from "@/views/TipoProductos/TipoProductos.vue";
import Proveedores from "@/views/Proveedores/Proveedores.vue";
import Asn from "@/views/Asn/Asn.vue";
import Asn_Recepcion from "@/views/Asn/Recepcion.vue";
import Docks from "@/views/Docks/Docks.vue";
import Lpn from "@/views/Lpn/Lpn.vue";
import Tasks from "@/views/Tasks/Tasks.vue";
import Inventory from "@/views/Inventory/Inventory.vue";

import Constant from "@/constants/sections";
/**
 * Routes applicant
 */
const routes_clients = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
];

/**
 * Routes general
 */
const routes_empleado = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_EMPLOYEES,
    component: Employees,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_ROLES,
    component: Roles,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_SUCURSALES,
    component: Sucursales,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_ALMACENES,
    component: Almacenes,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_CATEGORIAS_ZONAS,
    component: CategoriasZonas,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_CLIENTS,
    component: Clients,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_SUBCLIENTS,
    component: SubClients,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_TIPOS_UBICACIONES,
    component: TiposUbicaciones,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_ZONAS,
    component: Zonas,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_PRODUCTS,
    component: Productos,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_RUBROS,
    component: Rubros,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_SUBRUBROS,
    component: SubRubros,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_PRODUCT_TYPES,
    component: TipoProductos,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_SUPPLIER,
    component: Proveedores,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_ASN,
    component: Asn,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_ASN + "/recepcion/:id",
    component: Asn_Recepcion,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_DOCKS,
    component: Docks,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_LPN,
    component: Lpn,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_TASKS,
    component: Tasks,
    meta: { requiresAuth: true },
  },
  {
    path: "/" + Constant.SECTION_INVENTORY,
    component: Inventory,
    meta: { requiresAuth: true },
  },
];

var routes = null;
if (localStorage.getItem("user_type") == UserType.CLIENT) {
  routes = routes_clients;
} else {
  routes = routes_empleado;
}

export default routes;
