<template>
  <div>
    <Title :pages="[{ icon: 'barcode', page: page_title.toUpperCase() + 's' }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="'Crear ' + modal_title"
        :modal_title="modal_title"
        :page_title="page_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :editItem="mainItem"
      />

      <v-card class="mb-4">
        <div class="row mb-2 ps-3 pe-3 mt-0 mb-2">
          <div class="col-md-12">
            <div class="col-md-12" style="background: white">
              <p class="text-h6 mt-2 mb-0"><font-awesome-icon icon="filter" class="pr-2" />Filtros</p>
              <v-divider class="mt-0"></v-divider>
              <div class="row pt-2">
                <div class="col-md-4">
                  <v-text-field v-model="filters.code" density="comfortable" name="code" label="LPN" :disabled="disabled"></v-text-field>
                </div>

                <div class="col-md-8 mt-2" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter" prepend-icon="mdi-magnify">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <v-row align="center" class="mt-1">
            <v-col>
              <span>{{ page_title }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" single-line hide-details label="Buscar" density="compact"></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <div class="mb-4 mt-2" v-show="update_access">
          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <v-icon
                :color="selected.length == 0 ? 'grey' : 'purple'"
                @click="selected.length == 0 ? null : printSeleted()"
                :class="selected.length == 0 ? 'ml-4 cursor-default' : 'ml-4 cursor-pointer'"
                v-bind="props"
              >
                mdi-printer</v-icon
              >
            </template>
            <span>Imprimir seleccionados</span>
          </v-tooltip>
        </div>
        <v-data-table :headers="headers" :items="list" :search="search" :loading="loading" v-model="selected" show-select loading-text="Cargando...">
          <template v-slot:item="{ item, isSelected, toggleSelect }">
            <tr>
              <td class="pl-2">
                <v-checkbox style="margin: 0px; padding: 0px" hide-details :model-value="isSelected({ value: item.id })" @update:modelValue="toggleSelect({ value: item.id })" />
              </td>
              <td>{{ item.creation_date }}</td>
              <td>{{ item.code }}</td>
              <td>{{ item.flow_type.name }}</td>
              <td>{{ item.state.name }}</td>
              <td class="text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" small color="purple" class="mr-2" v-on:click="print(item)" size="small"> mdi-printer</v-icon>
                  </template>
                  <span>Imprimir</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" small color="blue" class="mr-2" v-on:click="viewHistory(item)" size="small"> mdi-timer</v-icon>
                  </template>
                  <span>Ver historial</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" :loading="loadingSubmit" />

      <ModalPrint :show="dialog_print" @close="dialog_print = false" :selected="selected" :list="list" @cleanSelected="selected = []" />

      <ModalHistory :show="dialog_history" @close="dialog_history = false" :code="code_history" />

      <v-pagination
        v-if="cant_pages > 1"
        v-model="current_page"
        :length="cant_pages"
        @next="loadPage(service)"
        @prev="loadPage(service)"
        @first="loadPage(service)"
        @update:modelValue="loadPage(service)"
      ></v-pagination>
    </v-container>
  </div>
</template>

<script>
import { lpnService } from "@/libs/ws/lpnService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import Modal from "./Modal.vue";
import ModalPrint from "./ModalPrint.vue";
import ModalHistory from "./ModalHistory.vue";
export default {
  components: { Modal, ModalPrint, ModalHistory },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      service: lpnService,
      page_title: "LPN",
      modal_title: "LPN",
      dialog_print: false,
      selected: [],
      dialog_history: false,
      code_history: null,
      filters: {
        code: "",
      },
      headers: [
        {
          title: "Fecha de creacion",
          align: "left",
          sortable: true,
          key: "creation_date",
        },
        {
          title: "LPN",
          align: "left",
          sortable: true,
          key: "code",
        },
        {
          title: "Tipo",
          align: "left",
          sortable: true,
          key: "flow_type.name",
        },
        {
          title: "Estado",
          align: "left",
          sortable: true,
          key: "state.name",
        },
        { title: "Accion", align: "center", sortable: false, key: "" },
      ],
    };
  },
  mounted() {
    this.loadList(this.service);
  },
  methods: {
    getFilterParams() {
      return this.filters;
    },
    filter() {
      this.loadList(this.service);
    },
    viewHistory(item) {
      this.code_history = item.code;
      this.dialog_history = true;
    },
    printSeleted() {
      this.dialog_print = true;
    },
    print(item) {
      this.selected = [];
      this.selected.push(item.id);
      this.dialog_print = true;
    },
  },
};
</script>
