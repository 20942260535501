<template>
  <v-layout>
    <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog', false)" max-width="900px" transition="dialog-bottom-transition">
      <template v-slot:activator="{ props }">
        <v-btn flat class="mb-3 btn-second" v-bind="props" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-6">
                <v-text-field label="Nombre*" v-model="item.name" :disabled="disabled" :rules="[rules.required]"></v-text-field>

                <v-combobox
                  v-model="item.sucursal"
                  item-title="name"
                  item-value="id"
                  :items="sucursales"
                  label="Sucursal*"
                  autocomplete="off"
                  :disabled="disabled"
                  :rules="[rules.requiredComboBox]"
                  clearable
                ></v-combobox>

                <div class="px-2 py-1 mb-3" style="background: rgb(221, 225, 230)">
                  <p class="text-subtitle-1 mb-0"><b>Dimensiones</b></p>
                </div>

                <v-text-field label="Alto (cm)" v-model="item.high" :disabled="disabled"></v-text-field>
                <v-text-field label="Ancho (cm)" v-model="item.width" :disabled="disabled"></v-text-field>
                <v-text-field label="Largo (cm)" v-model="item.length" :disabled="disabled"></v-text-field>
              </div>
              <div class="col-md-6">
                <div class="px-2 py-1 mb-3" style="background: rgb(221, 225, 230)">
                  <p class="text-subtitle-1 mb-0"><b>Configuración</b></p>
                </div>

                <v-text-field label="Peso Maximo (kg)" v-model="item.max_weight" :disabled="disabled"></v-text-field>
                <v-combobox
                  v-model="item.has_max_pallet"
                  :items="has_max_pallet_options"
                  label="¿Posee cantidad maxima de pallets almacenados?"
                  autocomplete="off"
                  :disabled="disabled"
                  :rules="[rules.requiredComboBoxOnlyValue]"
                  clearable
                ></v-combobox>

                <v-text-field v-if="item.has_max_pallet == 'Si'" label="Cantidad maxima de pallets" v-model="item.max_cant_pallets" :disabled="disabled"></v-text-field>
                                
                <v-checkbox label="Permitir Recepción" :disabled="disabled" v-model="item.allow_recepcion"></v-checkbox>
                <v-checkbox label="Permitir Picking" :disabled="disabled" v-model="item.allow_picking"></v-checkbox>
                <v-checkbox label="Permitir Expedición" :disabled="disabled" v-model="item.allow_expedicion"></v-checkbox>
                <v-checkbox label="Permitir Reposición" :disabled="disabled" v-model="item.allow_reposicion"></v-checkbox>
                <v-checkbox label="Permitir Crossdocking" :disabled="disabled" v-model="item.allow_crossdocking"></v-checkbox>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" :loading="loading" v-if="!disabled">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { sucursalService } from "@/libs/ws/sucursalService";
import { generalService } from "@/libs/ws/generalService";
import ValidMixin from "@/mixins/ValidMixin.vue";
export default {
  mixins: [ValidMixin],
  props: {
    service: Object,
    button_name: String,
    modal_title: String,
    page_title: String,
    formBtn: String,
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    editItem: Object,
  },
  data() {
    return {
      show: false,
      disabled: false,
      sucursales: [],
      has_max_pallet_options: ["Si", "No"],
      loading: false,
      name: "",
      item: {
        id: "",
        name: "",        
        sucursal: null,
        high: null,
        width: null,
        length: null,
        max_weight: null,        
        max_cant_pallets: null,

        allow_crossdocking: false,
        allow_recepcion: false,
        allow_picking: false,
        allow_expedicion: false,
        allow_reposicion: false,        
      },
      defaultItem: null,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.disabled = this.formBtn == "";

        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.editItem != null && this.editItem.id > 0) {
          this.item = JSON.parse(JSON.stringify(this.editItem));
        } else {
          if (this.defaultItem != null) this.item = JSON.parse(JSON.stringify(this.defaultItem));
        }
      }
    },
  },
  mounted() {
    this.loadSucursales();
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    loadSucursales() {
      sucursalService
        .filter()
        .then((response) => {
          this.sucursales = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    createItem() {
      if (this.loading) return;

      if (this.valid) {
        this.loading = true;

        var data = {
          id: this.item.id,
          name: this.item.name,          
          sucursal: { id: this.item.sucursal.id },
          high: this.item.high,
          width: this.item.width,
          length: this.item.length,
          max_weight: this.item.max_weight,          
          max_cant_pallets: this.item.max_cant_pallets,

          allow_recepcion: this.item.allow_recepcion,
          allow_picking: this.item.allow_picking,
          allow_expedicion: this.item.allow_expedicion,
          allow_reposicion: this.item.allow_reposicion,
          allow_crossdocking: this.item.allow_crossdocking
        };

        if (this.item.id > 0) {
          this.service
            .update(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se modifico correctamente.");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((response) => {
              this.toast.error(response.msg);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.service
            .create(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se creo correctamente");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((e) => {
              this.toast.error(e);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>
