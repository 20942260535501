import { wsService } from "./wsService";

const section = "tasks";

const TASK_TRANSFERENCIA= 1;
const TASK_PREPARACION = 2;
const TASK_EXPEDICION = 4;
const TASK_INVENTARIO = 5;

const STATE_PENDIENTE = "PEN";
const STATE_ASIGNADA = "ASI";
const STATE_EN_PROCESO = "PRO";
const STATE_FINALIZADO = "FIN";
const STATE_CANCELADA = "CAN";

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function cancel(param = {}) {
  return wsService.make_post_request(section + "/cancel", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function list_types(param = {}) {
  return wsService.make_post_request(section + "/list-types", param);
}list_states

function list_states(param = {}) {
  return wsService.make_post_request(section + "/list-states", param);
}

function perform_task(param = {}) {
  return wsService.make_post_request(section + "/perform-task", param);
}

export const taskService = {
  section,
  
  TASK_TRANSFERENCIA,
  TASK_PREPARACION,  
  TASK_EXPEDICION,
  TASK_INVENTARIO,

  STATE_PENDIENTE,
  STATE_ASIGNADA,
  STATE_EN_PROCESO,
  STATE_FINALIZADO,
  STATE_CANCELADA,

  filter,
  remove,
  create,
  update,
  cancel,
  list_types,
  list_states,
  perform_task,
};
