function generateValueBetween(start, end, step = 1) {
    const values = [];
    if(start.match(/[a-zA-Z]+/) != null && start.match(/[0-9]+/) != null){//Alphanumeric
        const letterStart = start.match(/[a-zA-Z]+/)[0];//AA
        const letterEnd = end.match(/[a-zA-Z]+/)[0];//ZZ

        const numericStart = start.match(/[0-9]+/)[0];//1
        const numericEnd = end.match(/[0-9]+/)[0]//9

        let currentLetter = letterStart;
        console.log(currentLetter,letterEnd)
        let currentNumeric;
        while (currentLetter !== letterEnd) {
            currentNumeric = numericStart;
            while (currentNumeric <= numericEnd) {
                const value = currentLetter + currentNumeric.toString().padStart(numericEnd.toString().length, "0");
                values.push(value);
                currentNumeric = incrementNumeric(currentNumeric,step);
            }
            currentLetter = incrementLetter(currentLetter,step);
        }
        const value = letterEnd + currentNumeric.toString().padStart(numericEnd.toString().length, "0");
        values.push(value);
    }else if(start.match(/[a-zA-Z]+/) == null && start.match(/[0-9]+/) != null){//Only numeric
        let currentNumeric = start;
        while (currentNumeric <= end) {
            const value =  currentNumeric.toString().padStart(end.toString().length, "0");
            values.push(value);
            currentNumeric = incrementNumeric(currentNumeric,step);
        }
    }else{//Only letters
        let current = start;
        while (current !== end) {
            values.push(current);
            current = incrementLetter(current,step);            
        }
        values.push(end); 
    }       
    return values;
}  
  
function incrementLetter(value, step) {
    const arr = value.split('');
  
    // Find the rightmost character that can be incremented
    let i = arr.length - 1;
    while (i >= 0 && (arr[i] === 'Z' || arr[i] > 'Z')) {
      i = i - 1;
    }
  
    if (i < 0) {
      // All characters are 'Z', so we need to add a new character 'A' at the beginning
      return 'A' + arr.join('').replace(/Z/g, 'A');
    }
  
    // Convert the character to its ASCII code and add the step
    const incrementedCharCode = arr[i].charCodeAt(0) + step;
  
    // Determine the new character based on the incremented ASCII code
    let newChar;
    if (incrementedCharCode > 90 && incrementedCharCode < 97) {
      newChar = String.fromCharCode(97); // Wrap around to 'a' if necessary
    } else if (incrementedCharCode > 122) {
      newChar = String.fromCharCode(65); // Wrap around to 'A' if necessary
    } else {
      newChar = String.fromCharCode(incrementedCharCode);
    }
  
    // Update the rightmost character with the new character
    arr[i] = newChar;
  
    // Reset all characters to the right of the incremented character to 'A'
    for (let j = i + 1; j < arr.length; j++) {
      arr[j] = 'A';
    }
  
    return arr.join('');
}
function incrementNumeric(str,step) {
    return parseInt(str) + parseInt(step);
}
  
export const ubicacionesUtils = {
    generateValueBetween,
};
  