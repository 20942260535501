<template>
  <v-layout>
    <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog', false)" fullscreen transition="dialog-bottom-transition">
      <template v-slot:activator="{ props }">
        <v-btn flat class="mb-3 btn-second" v-bind="props" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
            <font-awesome-icon class="float-right mt-1 mr-3 cursor" title="Cerrar" size="xl" icon="xmark" color="red" @click="$emit('updateDialog', false)"></font-awesome-icon>
          </v-card-title>

          <v-card-text>
            <v-container fluid class="mt-0">
              <v-row align="center" justify="center" class="mb-4">
                <v-col md="6" class="elevation-1 pt-4 pb-4" style="background: #f5f5f5">
                  <a-steps :current="step">
                    <a-step v-for="item in steps" :key="item.title" :title="item.title" />
                  </a-steps>
                </v-col>
              </v-row>
              <!-- STEP 1 -->
              <v-row align="start" justify="center" v-if="step == 0" transition="fade-transition" style="height: 62dvh">
                <v-col md="6" class="mt-4 pr-0 pl-0">
                  <div class="px-2 py-1 mb-3 bg-subtitle-section">
                    <p class="text-subtitle-1 mb-0"><b>Zona</b></p>
                  </div>
                  <v-form ref="form_step1">
                    <v-text-field label="Nombre*" name="name" v-model="item.name" :disabled="disabled" :rules="[rules.required]"></v-text-field>

                    <v-combobox
                      v-model="item.almacen"
                      item-title="name"
                      item-value="id"
                      :items="almacenes"
                      label="Almacen*"
                      autocomplete="off"
                      :disabled="disabled"
                      :rules="[rules.requiredComboBox]"
                      clearable
                    ></v-combobox>

                    <v-combobox
                      v-model="item.categoria_zona"
                      item-title="name"
                      item-value="id"
                      :items="categorias_zonas"
                      label="Categoria*"
                      autocomplete="off"
                      :disabled="disabled"
                      :rules="[rules.requiredComboBox]"
                      clearable
                    ></v-combobox>
                  </v-form>

                  <v-btn class="float-right mt-5 btn-second" v-if="step < steps.length - 1" @click="next">Siguiente</v-btn>
                </v-col>
              </v-row>
              <!-- FIN Step 1 -->
              <!-- INICIO Step 2 -->

              <div v-if="step == 1" style="min-height: 58dvh">
                <v-row class="mt-4" align="start" justify="center">
                  <v-col md="6" class="pr-0 pl-0">
                    <div class="px-2 py-1 mb-3 bg-subtitle-section">
                      <p class="text-subtitle-1 mb-0"><b>Ubicaciones</b></p>
                    </div>
                  </v-col></v-row
                >

                <v-row>
                  <v-col md="12">
                    <v-btn small color="blue-grey" class="text-white" @click="openModalUbicacion(null)" v-if="!disabled">
                      <v-icon big color="white"> mdi-plus </v-icon>
                      <span>Agregar Ubicacion</span>
                    </v-btn>

                    <v-btn small color="blue-grey" class="ml-3 text-white" @click="openQuickLoad()" v-if="!disabled">
                      <v-icon big color="orange"> mdi-lightning-bolt </v-icon>
                      <span>Carga rapida</span>
                    </v-btn>

                    <!--
                      <v-btn small color="blue-grey" class="ml-3 text-white" @click="deleteallUbicaciones()" v-if="!disabled">
                        <v-icon big color="white"> mdi-delete </v-icon>
                        <span>Borrar todas</span>
                      </v-btn>
                      -->

                    <v-data-table class="mt-2 mb-2" :headers="header_details" :items="item.ubicaciones" style="border: solid 1px #1c1c1c" :loading="loading_ubicaciones">
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>
                            {{ item.tipo_ubicacion.name }}
                          </td>
                          <td>
                            {{ item.rack }}
                          </td>
                          <td>
                            {{ item.col }}
                          </td>
                          <td>
                            {{ item.level }}
                          </td>
                          <td>
                            <v-checkbox hide-details label="" :disabled="true" v-model="item.store_confirmation"></v-checkbox>
                          </td>
                          <td>
                            <v-checkbox hide-details label="" :disabled="true" v-model="item.pickup_confirmation"></v-checkbox>
                          </td>
                          <td>
                            <v-checkbox hide-details label="" :disabled="true" v-model="item.virtual"></v-checkbox>
                          </td>
                          <td>
                            <v-checkbox hide-details label="" :disabled="true" v-model="item.gen_verification_digit"></v-checkbox>
                          </td>
                          <td>
                            <v-checkbox hide-details label="" :disabled="true" v-model="item.multiple_products"></v-checkbox>
                          </td>
                          <td>
                            <v-checkbox hide-details label="" :disabled="true" v-model="item.multiple_lotes"></v-checkbox>
                          </td>
                          <td>
                            <v-checkbox hide-details label="" :disabled="true" v-model="item.multiple_series"></v-checkbox>
                          </td>
                          <td>
                            {{ item.max_multiple_products }}
                          </td>
                          <td class="text-center" style="min-width: 100px">
                            <v-tooltip bottom v-if="!disabled">
                              <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" small color="blue" class="mr-2" v-on:click="openModalUbicacion(item)" size="small"> mdi-pencil</v-icon>
                              </template>
                              <span>Editar</span>
                            </v-tooltip>

                            <v-tooltip bottom v-if="!disabled">
                              <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" small color="red" class="mr-2" v-on:click="removeUbicacion(item)" size="small">mdi-delete </v-icon>
                              </template>
                              <span>Eliminar</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </div>

              <!-- FIN UBICACIONES -->
              <div class="steps-action">
                <v-btn v-if="step > 0" @click="prev" color="blue-grey">Atras</v-btn>
              </div>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" :loading="loading" v-if="!disabled && step > 0">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <ModalQuickLoad :dialog="dialog_quick_load" @closeDialog="dialog_quick_load = false" @processQuickLoad="processQuickLoad" />
    <ModalUbicacion
      :modal_title="itemUbicacion == null ? 'Agregar Ubicación' : 'Editar Ubicación'"
      :formBtn="itemUbicacion == null ? 'Agregar' : 'Editar'"
      :dialog="dialog_ubicacion"
      @handlerUbicacion="handlerUbicacion"
      @updateDialog="dialog_ubicacion = false"
      :editItem="itemUbicacion"
    />
  </v-layout>
</template>

<script>
import { almacenService } from "@/libs/ws/almacenService";
import { categoriaZonaService } from "@/libs/ws/categoriaZonaService";
import ValidMixin from "@/mixins/ValidMixin.vue";
import ModalQuickLoad from "./ModalQuickLoad.vue";
import ModalUbicacion from "./ModalUbicacion.vue";
export default {
  mixins: [ValidMixin],
  components: { ModalQuickLoad, ModalUbicacion },
  props: {
    service: Object,
    button_name: String,
    modal_title: String,
    page_title: String,
    formBtn: String,
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    editItem: Object,
  },
  data() {
    return {
      disabled: false,
      dialog_quick_load: false,
      dialog_ubicacion: false,
      almacenes: [],
      categorias_zonas: [],
      step: 0,
      steps: [
        {
          title: "Zona",
          content: "First-content",
        },
        {
          title: "Ubicaciones",
          content: "Second-content",
        },
      ],
      loading: false,
      loading_ubicaciones: false,
      item: {
        id: "",
        name: "",        
        almacen: null,
        categoria_zona: null,
        ubicaciones: [],
      },
      defaultItem: null,
      header_details: [
        { title: "Tipo", key: "tipo_ubicacion", sortable: false, class: "" },
        { title: "Rack", key: "rack", sortable: false, class: "bg-grey" },
        { title: "Columna", key: "col", sortable: false, class: "bg-grey" },
        { title: "Nivel", key: "level", sortable: false, class: "bg-grey" },
        { title: "Confirmar Almacenamiento", align: "center", key: "store_confirmation", sortable: false, class: "" },
        { title: "Confirmar Pickup", align: "center", key: "pickup_confirmation", sortable: false, class: "" },
        { title: "Virtual", key: "virtual", sortable: false, class: "bg-blue" },
        { title: "Digito Verificador", align: "center", key: "gen_verification_digit", sortable: false, class: "" },
        { title: "Permitir mult. prod.", align: "center", key: "multiple_products", sortable: false, class: "" },
        { title: "Permitir mult. lotes", align: "center", key: "multiple_lotes", sortable: false, class: "" },
        { title: "Permitir mult. series", align: "center", key: "multiple_series", sortable: false, class: "" },
        { title: "Cant. max. de prod.", key: "max_multiple_products", sortable: false, class: "" },
        { title: "Accion", key: "accion", sortable: false, class: "" },
      ],
      itemUbicacion: null,
      editedUbicacionIndex: null,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.disabled = this.formBtn == "";
        this.step = 0;

        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.editItem != null && this.editItem.id > 0) {
          this.item = JSON.parse(JSON.stringify(this.editItem));
        } else {
          if (this.defaultItem != null) this.item = JSON.parse(JSON.stringify(this.defaultItem));
        }
      }
    },
  },
  mounted() {
    this.loadAlmacenes();
    this.loadCategoriasZonas();

    this.defaultItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    prev() {
      this.step--;
    },
    async next() {
      const { valid } = await this.$refs.form_step1.validate();

      if (valid) {
        this.step++;
      }
    },

    loadAlmacenes() {
      almacenService
        .filter()
        .then((response) => {
          this.almacenes = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadCategoriasZonas() {
      categoriaZonaService
        .filter()
        .then((response) => {
          this.categorias_zonas = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    handlerUbicacion(ubicacion) {
      if (this.editedUbicacionIndex != null) {
        this.item.ubicaciones[this.editedUbicacionIndex] = ubicacion;
      } else {
        this.item.ubicaciones.push(ubicacion);
      }
      this.dialog_ubicacion = false;
      this.editedUbicacionIndex = null;
    },
    openModalUbicacion(item) {
      if (item != null) {
        this.editedUbicacionIndex = this.item.ubicaciones.indexOf(item);
      }
      this.itemUbicacion = item;
      this.dialog_ubicacion = true;
    },
    openQuickLoad() {
      this.dialog_quick_load = true;
    },
    removeUbicacion(item) {
      var index_item = this.item.ubicaciones.indexOf(item);
      if (index_item > -1) this.item.ubicaciones.splice(index_item, 1);
    },
    processQuickLoad(ubicaciones) {
      this.loading_ubicaciones = true;
      this.item.ubicaciones = this.item.ubicaciones.concat(ubicaciones);
      this.loading_ubicaciones = false;
    },
    deleteallUbicaciones() {
      this.item.ubicaciones = [];
    },
    createItem() {
      if (this.loading) return;

      if (this.valid) {
        this.loading = true;

        //Se hace para que el parseo de array no reemplace la propiedad "ubicaciones" por ubicaciones_arr
        const data = {
          id: this.item.id,
          name: this.item.name,          
          almacen: this.item.almacen,
          categoria_zona: this.item.categoria_zona,
          ubicaciones_arr: this.item.ubicaciones,
        };

        if (this.item.id > 0) {
          this.service
            .update(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se modifico correctamente.");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((response) => {
              this.toast.error(response.msg);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.service
            .create(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se creo correctamente");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((e) => {
              this.toast.error(e);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>
