const EMPLOYEE = "E";
const CLIENT = "C";
const SUPERUSER = "A";


export default { 
    EMPLOYEE: EMPLOYEE,
    CLIENT: CLIENT,
    SUPERUSER: SUPERUSER,
};
