import { wsService } from "./wsService";
const api_url = process.env.VUE_APP_API_URL;
const section = "lpn_labels";
import axios from "axios";

const FLOW_TYPE_RECEPTION = "I";
const FLOW_TYPE_EXPEDITION = "O";

const STATE_NO_USADO = "N";

function filter(param = { enable: true }) {
  /*PARAMS:
  code: string
  flow_type: string

  almacen.id: integer
  zona.id: integer
  ubicacion.id: integer
  product.id: integer
  */
  return wsService.make_post_request(section + "/list", param);
}

function filter_assigned_locations(param = {}) {
  /*PARAMS:
  code: string
  flow_type: string

  almacen.id: integer
  zona.id: integer
  ubicacion.id: integer
  product.id: integer
  */
  return wsService.make_post_request(section + "/filter-assigned-locations", param);
}

function get_ubicacion(param = {}) {  
  /*PARAMS:
  lpn.id: integer
  lpn_code: string
  */
  return wsService.make_post_request(section + "/get-ubicacion", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function getHistory(param = {}) {
  //return wsService.make_post_request(section + "/history/" + code, param);
  return wsService.make_post_request(section + "/history", param);
}

function getInboundNotUsed() {
  var param = {
    flow_type: FLOW_TYPE_RECEPTION,
    state: STATE_NO_USADO,
    from: 0,
    limit: 100,
  };
  return wsService.make_post_request(section + "/list", param);
}

function getOutboundNotUsed() {
  var param = {
    flow_type: FLOW_TYPE_EXPEDITION,
    state: STATE_NO_USADO,
  };
  param = {};
  return wsService.make_post_request(section + "/list", param);
}

function print(param = {}, type) {
  var url = "";
  if (type == "zpl") {
    url = section + "/print/label/bulk/termica_zpl";
  }
  if (type == "pdf") {
    url = section + "/print/label/bulk/termica_pdf";
  }
  const token = localStorage.getItem("token");
  return axios.post(api_url + url, param, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/pdf",
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + token,
    },
  });
}

export const lpnService = {
  section,
  filter,
  filter_assigned_locations,
  remove,
  create,
  update,
  print,
  getHistory,
  getInboundNotUsed,
  getOutboundNotUsed,
  get_ubicacion,

  FLOW_TYPE_RECEPTION,
  FLOW_TYPE_EXPEDITION,
  STATE_NO_USADO,
};
