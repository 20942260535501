<template>
  <div>
    <v-dialog :modelValue="dialog_delete" max-width="450">
      <v-card>
        <v-card-title class="headline">Confirmar</v-card-title>

        <v-card-text>
          <p class="mt-3 mb-0 fs-16 text-center">¿Esta seguro que quiere eliminar este elemento?</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="$emit('updateDialogDelete', false)"> No </v-btn>

          <v-btn color="green darken-1" text @click="$emit('deleteItemConfirm')" :loading="loading"> Si </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialog_delete: Boolean,
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({}),
  methods: {},
};
</script>
