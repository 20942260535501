<template>
  <div>
    <v-layout class="pull-left">
      <v-dialog :modelValue="show" max-width="1000px" @keydown.esc="closeModal()" @click:outside="closeModal()">
        <v-card>
          <v-card-title class="headline">
            <span>Detalle de tare #{{ (task != null)?task.id:'' }}</span>
          </v-card-title>

          <v-card-text>
            <v-data-table class="mt-4" :headers="headers" :items="history" :loading="loading_history" loading-text="Cargando...">
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.date_time }}</td>
                  <td>{{ item.user.username }}</td>
                  <td>{{ item.origin_ubicacion != null ? item.origin_ubicacion.name : "-" }}</td>
                  <td>{{ item.destination_ubicacion != null ? item.destination_ubicacion.name : "-" }}</td>
                  <td>{{ item.presentation_atributes.product_presentation.product.name }}</td>
                  <td>{{ item.presentation_atributes.product_status.name }}</td>
                  <td>{{ item.presentation_atributes.lote }}</td>
                  <td>{{ item.presentation_atributes.serie }}</td>                  
                  <td>{{ item.stock }}</td>
                  <td>{{ item.task != null && item.task.asn_detail_amounts_received != null ? "#" + item.task.asn_detail_amounts_received.id : "-" }}</td>                  
                </tr>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeModal()">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import { lpnService } from "@/libs/ws/lpnService";
export default {
  props: {
    show: Boolean,
    close: Function,
    task: Object,
  },
  name: "ModalHistory",
  mixins: [],
  created() {},
  data() {
    return {
      loading_history: false,
      history: [],
      headers: [
        {
          title: "Fecha",
          align: "left",
          sortable: true,
          key: "code",
        },
        {
          title: "Usuario",
          align: "left",
          sortable: false,
          key: "flow_type.name",
        },
        {
          title: "Ubicacion Origen",
          align: "left",
          sortable: false,
          key: "state.name",
        },        
        {
          title: "Ubicacion Destino",
          align: "left",
          sortable: false,
          key: "state.name",
        },
        { title: "Producto", align: "left", sortable: false, key: "presentation_atributes.product_presentation.product.name" },
        { title: "Estado", align: "left", sortable: false, key: "presentation_atributes.product_status.name" },
        { title: "Lote", align: "left", sortable: false, key: "presentation_atributes.lote" },
        { title: "Serie", align: "left", sortable: false, key: "presentation_atributes.serie" },
        {
          title: "Stock",
          align: "center",
          sortable: false,
          key: "",
        },
        {
          title: "ASN - Recepcion",
          align: "center",
          sortable: false,
          key: "",
        },        
      ],
    };
  },
  mounted() {},
  computed: {},
  watch: {
    task() {
      this.getHistory();
    },
  },
  methods: {
    closeModal(){
      this.$emit('close');
      this.history = [];
    },
    getHistory() {   
      if(this.task == null || !(this.task.id > 0)) return;

      this.loading_history = true;
      const param = {
        task: this.task,
      };
      lpnService
        .getHistory(param)
        .then((response) => {
          this.history = response.result.list;          
        })
        .catch((error) => {
          this.toast.error(error);          
        })
        .finally(() => {
          this.loading_history = false;
        });     
    },
  },
};
</script>
