import { wsService } from "./wsService";

var section = "";

function request(path, type = "GET", param = {}) {
  if (type == "GET") {
    return wsService.make_get_request(path);
  } else {
    return wsService.make_post_request(path, param);
  }
}

function filter(param = {}, path = section) {
  return wsService.make_post_request(path + "/list", param);
}

function get(id, path = section) {
  return wsService.make_get_request(path + "/get/" + id);
}

function create(param = {}, path = section) {
  return wsService.make_post_request(path + "/create", param);
}

function update(param = {}, path = section) {
  return wsService.make_put_request(path + "/update", param);
}

function update_wfile(param = {}, path = section) {
  return wsService.make_post_request(path + "/update", param);
}

function remove(param = {}, path = section) {
  return wsService.make_delete_request(path + "/delete", param);
}

function setSection(s) {
  section = s;
}

function downloadPdf(response, filename) {
  if (Object.prototype.toString.call(response) != "[object ArrayBuffer]" && !response.status) {
    return false;
  }

  let blob = new Blob([response], { type: "application/pdf" });
  let link = document.createElement("a");
  link.download = filename;
  link.href = window.URL.createObjectURL(blob);
  link.target = "_blank";
  link.click();
  return true;
}

export const generalService = {
  section,
  request,
  setSection,
  filter,
  get,
  remove,
  create,
  update,
  update_wfile,

  downloadPdf,
};
