import { requestService } from "./requestService";

const api_url = process.env.VUE_APP_API_URL;
const timeout = 600000; //ms
//console.log(api_url)

function make_post_request(section, param, intento = 0) {
  return requestService.make_post_request(api_url, timeout, section, param, intento).catch((e) => {
    const error_result = requestService.handle_error("POST", e);
    return error_result;
  });
}

function make_get_request(section, intento = 0) {
  return requestService.make_get_request(api_url, timeout, section, intento).catch((e) => {
    const error_result = requestService.handle_error("GET", e);
    return error_result;
  });
}

function make_delete_request(section, param, intento = 0) {
  return requestService.make_delete_request(api_url, timeout, section, param, intento).catch((e) => {
    const error_result = requestService.handle_error("GET", e);
    return error_result;
  });
}

function make_put_request(section, param, intento = 0) {
  return requestService.make_put_request(api_url, timeout, section, param, intento).catch((e) => {
    const error_result = requestService.handle_error("GET", e);
    return error_result;
  });
}

export const wsService = {
  make_post_request,
  make_get_request,
  make_delete_request,
  make_put_request,
};
