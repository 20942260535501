import { wsService } from "./wsService";

const section = "inventory";

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

export const inventoryService = {
  section,
  filter,
};
