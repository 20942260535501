<template>
  <div class="row w-100 header">
    <div class="col-md-12 pe-0 me-0">
      <v-toolbar color="#28a745" app height="48" dark dense class="elevation-0">
        <v-tooltip bottom :open-delay="1000">
          <template v-slot:activator="{ props }">
            <v-app-bar-nav-icon v-bind="props" @click="handleSidebar" color="#ffffff"></v-app-bar-nav-icon>
          </template>
          <span>{{ $store.state.sidebar.open ? "Contraer Menu" : "Expandir Menu" }}</span>
        </v-tooltip>

        <div class="position-absolute d-flex align-items-center" style="right: 5px">
          <p class="mb-0 mr-2 text-white">{{ getUsername() }}</p>
          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <v-btn v-on:click="dialog_logout = true" icon v-bind="props">
                <v-icon color="#ffffff">mdi-export</v-icon>
              </v-btn>
            </template>
            <span>Cerrar sesión</span>
          </v-tooltip>
        </div>
      </v-toolbar>

      <DialogYesNo :open="dialog_logout" title="Confirmar" message="¿Estas seguro que quieres cerrar sesion?" @cancel="dialog_logout = false" @accept="logoutConfirm" />
    </div>
  </div>
</template>
<script>
import { authService } from "@/libs/ws/authService";

export default {
  name: "App",
  components: {},
  data() {
    return {
      dialog_logout: false,
    };
  },
  mounted() {
    /* this.$i18n.locale = "en"; */
  },
  methods: {
    goutModal() {
      this.dialog_logout = true;
    },
    handleSidebar() {
      if (window.innerWidth >= 1264) {
        this.$store.commit("setOpenSidebar", {
          open: !this.$store.state.sidebar.open,
        });
      } else {
        this.$store.commit("setOpenSidebar", {
          open: false,
          drawer: !this.$store.state.sidebar.drawer,
        });
      }
    },
    getUsername() {
      return localStorage.getItem("username");
    },
    logoutConfirm() {
      authService.logOut();
      this.$router.push("/login");
    },
    langSelector(name) {
      this.$i18n.locale = name;
      localStorage.setItem("lang", name);
      location.reload();
    },
  },
};
</script>
