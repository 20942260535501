<template>
  <div>
    <v-dialog :modelValue="dialog" max-width="400">
      <v-card>
        <v-progress-linear indeterminate color="blue darken-2"></v-progress-linear>
        <v-card-text>
          <div>
            <v-container style="height: 20px">
              <v-row class="fill-height" align-content="center" justify="center">
                <v-col class="subtitle-1 text-center" cols="12">
                  {{ text }}
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialog: Boolean,
    text: String,
  },
  data: () => ({}),
  methods: {},
};
</script>
