<template>
  <v-layout>
    <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog', false)" max-width="1000px" transition="dialog-bottom-transition">
      <template v-slot:activator="{ props }">
        <v-btn flat class="mb-3 btn-second" v-bind="props" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <v-row align="center" justify="center" class="mb-4 mt-4">
              <v-col md="10" class="elevation-1 pt-4 pb-4" style="background: #f5f5f5">
                <a-steps :current="step">
                  <a-step v-for="item in steps" :key="item.title" :title="item.title" />
                </a-steps>
              </v-col>
            </v-row>

            <div class="steps-content">
              <!--step 0-->
              <v-row align="start" justify="center" v-if="step == 0" transition="fade-transition">
                <v-col md="10" class="mt-4 pr-0 pl-0">
                  <v-form ref="form_step1">
                    <div class="px-2 py-1 mb-3 bg-subtitle-section">
                      <p class="text-subtitle-1 mb-0"><b>Datos Generales</b></p>
                    </div>
                    <p>Complete los datos generales del producto</p>
                    <v-row>
                      <v-col md="4">
                        <v-text-field label="Código (SKU)*" name="sku" v-model="item.sku" :rules="[rules.required]" :disabled="disabled"></v-text-field>
                      </v-col>
                      <v-col md="8">
                        <v-text-field label="Nombre*" v-model="item.name" :disabled="disabled" :rules="[rules.required]"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="6">
                        <v-combobox
                          v-model="item.client"
                          name="cliente"
                          item-title="name"
                          item-value="id"
                          :items="clients"
                          label="Cliente*"
                          autocomplete="off"
                          :disabled="disabled"
                          :rules="[rules.requiredComboBox]"
                          clearable
                        ></v-combobox>
                      </v-col>
                      <v-col md="6">
                        <v-combobox
                          v-model="item.supplier"
                          name="proveedor"
                          item-title="name"
                          item-value="id"
                          :items="suppliers"
                          label="Proveedor"
                          autocomplete="off"
                          :disabled="disabled"
                          clearable
                        ></v-combobox>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col md="6">
                        <v-combobox
                          v-model="item.category"
                          name="category"
                          item-title="name"
                          item-value="id"
                          :items="categories"
                          label="Rubro*"
                          autocomplete="off"
                          :disabled="disabled"
                          :rules="[rules.requiredComboBox]"
                          clearable
                          @update:modelValue="comboCategoryChange"
                        ></v-combobox>
                      </v-col>
                      <v-col md="6">
                        <v-combobox
                          v-model="item.subcategory"
                          name="subcategory"
                          item-title="name"
                          item-value="id"
                          :items="subcategories"
                          label="Subrubro"
                          autocomplete="off"
                          :disabled="disabled"
                          clearable
                        ></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="6">
                        <v-combobox
                          v-model="item.product_type"
                          name="product_type"
                          item-title="name"
                          item-value="id"
                          :items="product_types"
                          label="Tipo de producto"
                          autocomplete="off"
                          :disabled="disabled"
                          clearable
                        ></v-combobox>
                      </v-col>
                      <v-col md="3">
                        <v-text-field label="Stock minimo" name="stock_minimo" v-model="item.stock_min" :disabled="disabled"></v-text-field>
                      </v-col>
                      <v-col md="3">
                        <v-text-field label="Stock maximo" name="stock_maximo" v-model="item.stock_max" :disabled="disabled"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="6">
                        <v-combobox
                          v-model="item.tipo_rotacion"
                          name="tipo_rotacion"
                          item-title="name"
                          item-value="id"
                          :items="tipos_rotacion"
                          label="Tipo de rotacion"
                          autocomplete="off"
                          :disabled="disabled"
                          clearable
                        ></v-combobox>
                      </v-col>
                      <v-col md="6">
                        <v-combobox
                          v-model="item.frecuencia_rotacion"
                          name="frecuencia_rotacion"
                          item-title="name"
                          item-value="id"
                          :items="frecuencias_rotacion"
                          label="Frecuencia de rotacion"
                          autocomplete="off"
                          :disabled="disabled"
                          clearable
                        ></v-combobox>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col md="6">
                        <v-checkbox density="compact" label="Tiene control por lote" v-model="item.tiene_control_por_lotes" :disabled="disabled"></v-checkbox>
                      </v-col>
                      <v-col md="6">
                        <v-checkbox density="compact" label="Tiene control por serie" v-model="item.tiene_control_por_serie" :disabled="disabled"></v-checkbox>
                      </v-col>
                      <v-col md="6">
                        <v-checkbox density="compact" label="Requiere fecha de expiracion" v-model="item.requiere_fecha_expiracion" :disabled="disabled"></v-checkbox>
                      </v-col>
                      <v-col md="6">
                        <v-checkbox density="compact" label="Discontinuado" v-model="item.discontinuado" :disabled="disabled"></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-btn class="float-right btn-second" v-if="step < steps.length - 1" @click="next">Siguiente</v-btn>
                </v-col>
              </v-row>

              <!--step 1-->
              <v-row align="start" justify="center" v-if="step == 1" transition="fade-transition">
                <v-col md="10" class="mt-4 pr-0 pl-0">
                  <div class="px-2 py-1 mb-3 bg-subtitle-section">
                    <p class="text-subtitle-1 mb-0"><b>Presentación</b></p>
                  </div>
                  <p>Ingrese las presentaciones de los productos</p>

                  <TablePresentations :product="item" :open="dialog" :validator="$validator" :isViewModal="disabled" @addProduct="addProduct" @removeProduct="removeProduct" />

                  <v-btn v-if="step > 0" @click="prev" class="mt-5" color="blue-grey">Atras</v-btn>
                  <v-btn class="float-right mt-5 btn-second" v-if="step < steps.length - 1" @click="next">Siguiente</v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" :loading="loading" v-if="!disabled && step == 1">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { clientService } from "@/libs/ws/clientService";
import { proveedorService } from "@/libs/ws/proveedorService";
import { rubroService } from "@/libs/ws/rubroService";
import { subrubroService } from "@/libs/ws/subrubroService";
import { productTypeService } from "@/libs/ws/productTypeService";
import ValidMixin from "@/mixins/ValidMixin.vue";
import TablePresentations from "./TablePresentations.vue";
import { productoService } from "@/libs/ws/productoService";
export default {
  components: { TablePresentations },
  mixins: [ValidMixin],
  props: {
    service: Object,
    button_name: String,
    modal_title: String,
    page_title: String,
    formBtn: String,
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    editItem: Object,
  },
  data() {
    return {
      show: false,
      disabled: false,
      categories: [],
      subcategories: [],
      clients: [],
      suppliers: [],
      product_types: [],
      tipos_rotacion: [],
      frecuencias_rotacion: [],
      loading: false,
      step: 0,
      steps: [
        {
          title: "Datos Generales",
          content: "First-content",
        },
        {
          title: "Presentación",
          content: "Second-content",
        },
      ],
      item: {
        id: "",
        sku: "",
        name: "",
        client: null,
        stock_min: 0,
        stock_max: 0,
        supplier: null,
        category: null,
        subcategory: null,
        product_type: null,
        tipo_rotacion: null,
        frecuencia_rotacion: null,
        discontinuado: false,
        tiene_control_por_lotes: false,
        tiene_control_por_serie: false,
        requiere_fecha_expiracion: false,
        presentations: [],
      },
      defaultItem: null,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.disabled = this.formBtn == "";

        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.editItem != null && this.editItem.id > 0) {
          this.item = JSON.parse(JSON.stringify(this.editItem));
        } else {
          if (this.defaultItem != null) this.item = JSON.parse(JSON.stringify(this.defaultItem));
        }
      }
    },
  },
  mounted() {
    this.loadClientes();
    this.loadProveedores();
    this.loadCategorias();
    this.loadProductTypes();
    this.loadRotaciones();
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    prev() {
      this.step--;
    },
    async next() {
      //this.step++;

      const { valid } = await this.$refs.form_step1.validate();

      if (valid) {
        this.step++;
      }
    },
    comboCategoryChange(event) {
      this.loadSubcategorias();
    },
    loadProductTypes() {
      productTypeService
        .filter()
        .then((response) => {
          this.product_types = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadClientes() {
      clientService
        .filter({})
        .then((response) => {
          this.clients = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadRotaciones() {
      productoService
        .tiposRotacoin()
        .then((response) => {
          this.tipos_rotacion = response.result;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
      productoService
        .frecuenciasRotacoin()
        .then((response) => {
          this.frecuencias_rotacion = response.result;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadProveedores() {
      proveedorService
        .filter({})
        .then((response) => {
          this.suppliers = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadCategorias() {
      rubroService
        .filter()
        .then((response) => {
          console.log("categorias", response.result);
          this.categories = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadSubcategorias() {
      subrubroService
        .filter({ category: this.item.category.id }, Constant.SECTION_SUBCATEGORIAS)
        .then((response) => {
          console.log("subcategorias", response.result);
          this.subcategories = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    addProduct(item, index = -1) {
      console.log("addProduct", item);
      if (index == -1) this.item.presentations.push(item);
      else this.item.presentations[index] = item;
      console.log("this.item.presentations", this.item.presentations);
    },
    removeProduct(index) {
      this.item.presentations.splice(index, 1);
    },
    createItem() {
      if (this.loading) return;

      if (this.valid) {
        this.loading = true;

        var data = JSON.parse(JSON.stringify(this.item));
        data.presentations_arr = data.presentations;
        delete data.presentations;
        if (this.item.id > 0) {
          this.service
            .update(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se modifico correctamente.");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((response) => {
              this.toast.error(response.msg);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.service
            .create(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se creo correctamente");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((e) => {
              this.toast.error(e);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>
