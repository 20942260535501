<script>
import UserTypes from "@/constants/user_type";
// definir un objeto mixin
export default {
  data() {
    return {
      show: true,
      dialog: false,
      rowsPerPageItems: [10, 20, 30, 40],
      pagination: {
        page: 1,
        itemsPerPage: 5,
      },
      dialog_delete: false,
      search: "",
      rowNumber: 0,
      deleteIndex: 0,
      deleteId: 0,
      editedIndex: -1,
      viewdIndex: -1,
      loading: false,
      list: [],
      selected: [],
      cant_pages: 0,
      total: 0,
      current_page: 1,
      name_filter: "",
      active_filter: 1,
      open_filter: false,
      mainItem: null,
      loadingSubmit: false,
    };
  },
  created: function () {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        var dialog_count = document.querySelectorAll("div.v-dialog");
        if (dialog_count.length == 1) {
          this.close();
        }
      }
    });
  },
  computed: {
    formBtn() {
      if (this.editedIndex === -1 && this.viewdIndex === -1) {
        return "Crear";
      } else if (this.viewdIndex === -1) {
        return "Editar";
      }
      return "";
    },
  },
  mounted() {
    //console.log("client",JSON.parse(localStorage.getItem("app_object")).client)
  },
  methods: {
    loadPage(service) {
      this.loadList(
        service,
        (this.current_page - 1) * this.$store.state.config.items_per_page,
        this.$store.state.config.items_per_page
      );
    },
    isEmployee() {
      return (
        localStorage.getItem("user_type") == UserTypes.EMPLOYEE ||
        localStorage.getItem("user_type") == UserTypes.SUPERUSER
      );
    },
    getAppObject() {
      return JSON.parse(localStorage.getItem("app_object"));
    },
    expandFilter() {
      if (this.open_filter) {
        this.open_filter = false;
      } else {
        this.open_filter = true;
      }
    },
    updateDialog(value) {
      if (!value) {
        this.close();
      }
      this.dialog = value;
    },
    updateDialogDelete(value) {
      this.dialog_delete = value;
    },
    viewItem(item) {
      this.viewdIndex = this.list.indexOf(item);
      this.mainItem = Object.assign({}, item);
      this.dialog = true;
    },
    editItem(item) {
      this.editedIndex = this.list.indexOf(item);
      this.mainItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.deleteIndex = this.list.indexOf(item);
      this.deleteId = item.id;
      this.dialog_delete = true;
    },
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service);
    },
    close() {
      this.mainItem = null;
      this.dialog = false;
      this.editedIndex = -1;
      this.viewdIndex = -1;
    },
    back() {
      this.$router.back();
    },
    updatePagination(pagination) {
      this.pagination = pagination;
    },
    getFilterParams() {
      var param = {};
      return param;
    },
    loadList(service) {
      this.loading = true;

      const param = {
        enable: true,
        from: this.current_page,
        limit: this.$store.state.config.items_per_page,
      };

      //agrego los parametros a param
      Object.assign(param, this.getFilterParams());

      this.open_filter = false;
      service
        .filter(param)
        .then((response) => {
          if (param.from == 0) {
            this.current_page = 1;
          }
          this.list = response.result.list;
          this.cant_pages = Math.ceil(
            response.result.total / this.$store.state.config.items_per_page
          );
          this.total = response.result.total;
          this.loading = false;

          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    delete(service) {
      this.loadingSubmit = true;
      service
        .remove({ id: this.deleteId })
        .then((response) => {
          if (response.status === true) {
            this.toast.success(response.msg);
            this.loadList(service);
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.toast.error(response.message);
            } else {
              this.toast.error(response.msg);
            }
          }
        })
        .catch((e) => {
          this.toast.error("Error exception");
        })
        .finally(() => {
          this.loadingSubmit = false;
        });
    },
  },
};
</script>
