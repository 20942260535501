<template>
  <div>
    <v-row>
      <v-col md="12" style="min-height: 250px" class="align-items-center d-flex">
        <v-radio-group class="justify-content-center d-flex items-align-center" v-model="movement_type_selected" row :rules="[rules.required]" :disabled="disabled">
          <v-radio value="lpn" class="mb-3" label="Transferir un pallet completo hacia una ubicacion de destino"></v-radio>
          <v-radio value="product" label="Transferir productos de un pallet hacia una ubicacion de destino"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-btn class="float-right btn-second mt-5" @click="nextStep()">Siguiente</v-btn>
  </div>
</template>

<script>
import ValidMixin from "@/mixins/ValidMixin.vue";

export default {
  created() {},
  mixins: [ValidMixin],
  components: {},
  props: {
    form_ref: Object,
    task: Object,
    setStep: Function,
    addPropertyToTask: Function,
    disabled: Boolean,
  },
  data: () => ({
    movement_type_selected: null,
  }),
  watch: {},
  mounted() {
    this.movement_type_selected = this.task.movement_type;
  },
  methods: {
    async nextStep() {
      const { valid } = await this.form_ref.validate();
      if (!valid) {
        this.toast.error("Complete los campos requeridos");
        return;
      }

      this.$emit("addPropertyToTask", "movement_type", this.movement_type_selected);
      this.$emit("setStep", 2);
    },
  },
};
</script>
