<template>
  <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog')" width="750">
    <v-card>
      <v-card-title class="title-modal">
        <span class="headline">DETALLE ASN</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-combobox
            v-model="item.product"
            name="product"
            item-title="name"
            item-value="id"
            :items="products"
            label="Producto*"
            autocomplete="off"
            :rules="[rules.requiredComboBox]"
            @update:modelValue="loadPresentations"
            clearable
          ></v-combobox>

          <v-combobox
            v-model="item.product_presentation"
            name="product_presentation"
            item-title="name"
            item-value="id"
            :items="presentation_products"
            label="Presentacion*"
            autocomplete="off"
            :rules="[rules.requiredComboBox]"
          ></v-combobox>

          <!-- validar numerico  -->
          <v-text-field autocomplete="off" name="expected_amount" label="Cantidad Esperada*" type="text" v-model="item.expected_amount" :rules="[rules.required]" />
          <!--
          <v-text-field autocomplete="off" name="lpn" label="LPN" type="text" v-model="item.lpn" />

          <v-text-field autocomplete="off" name="expected_lote" label="Lote" type="text" v-model="item.expected_lote" />

          <v-row>
            <v-col cols="6">
              <v-text-field v-model="item.expected_manufacture_date" name="expected_manufacture_date" label="Fecha de Fabricación" scrollable type="date"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="item.expected_expiration_date" name="expected_expiration_date" label="Fecha de Expiración" scrollable type="date"></v-text-field>
            </v-col>
          </v-row>

          <v-text-field autocomplete="off" name="external_line_number" label="Numero de linea externo" type="text" v-model="item.external_line_number" />
          -->
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="$emit('updateDialog')"> Cancelar </v-btn>
        <v-btn color="green" text @click="addNewPresentation()"> {{ itemEdit != null ? "Editar" : "Agregar" }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { productoService } from "@/libs/ws/productoService";
import ValidMixin from "@/mixins/ValidMixin.vue";

export default {
  created() {},
  mixins: [ValidMixin],
  components: {},
  props: {
    dialog: Boolean,
    asn: Object,
    updateDialog: Function,
    addPresentation: Function,
    itemEdit: Object,
  },
  data: () => ({
    item: {
      lpn: "",
      product_presentation: null,
      expected_amount: null,
      expected_manufacture_date: null,
      expected_expiration_date: null,
      expected_lote: null,
      external_line_number: "",
    },
    defaultItem: null,
    presentation_products: [],
    products: [],
  }),
  watch: {
    dialog() {
      if (this.itemEdit == null) {
        this.item = JSON.parse(JSON.stringify(this.defaultItem));
      } else {
        this.item = JSON.parse(JSON.stringify(this.itemEdit));
      }
    },
  },
  mounted() {
    this.loadProducts();
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    loadProducts() {
      productoService.filter({ client: this.asn.client.id }).then((response) => {
        this.products = response.result.list;
      });
    },
    loadPresentations() {
      this.presentation_products = this.item.product.presentations;
      if (this.item.product.presentations.length == 1) {
        this.item.product_presentation = this.item.product.presentations[0];
      }
    },
    async addNewPresentation() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.$emit("addPresentation", this.item);
      }
    },
  },
};
</script>
